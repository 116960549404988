import { useEffect, useState } from "react";
import { Popover } from "antd";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { setDirectionRTL, switchLanguage } from "../../appRedux/actions";
import AppLocale from "../../lngProvider";
import CustomScrollbars from "../../util/CustomScrollbars";

const LanguageMenu = (props: any) => {
  const dispatch = useDispatch();

  const intl = useIntl();
  const languageOptions = [
    {
      key: "tr",
      name: intl.formatMessage({ id: "settings.lang.turkish" }),
      flag: "🇹🇷",
      icon: "tr",
    },
    {
      key: "es",
      name: intl.formatMessage({ id: "settings.lang.spanish" }),
      flag: "ES",
      icon: "es",
    },
    {
      key: "en",
      name: intl.formatMessage({ id: "settings.lang.english" }),
      flag: "🇺🇸",
      icon: "us",
    },
    {
      key: "fr",
      name: intl.formatMessage({ id: "settings.lang.french" }),
      flag: "FR",
      icon: "fr",
    },
    {
      key: "it",
      name: intl.formatMessage({ id: "settings.lang.italian" }),
      flag: "IT",
      icon: "it",
    },
    {
      key: "ar",
      name: intl.formatMessage({ id: "settings.lang.arabic" }),
      flag: "🇸🇦",
      icon: "sa",
    },
    {
      key: "hans",
      name: intl.formatMessage({ id: "settings.lang.chinese" }),
      flag: "ZH",
      icon: "cn",
    },
  ];

  const getInitialLanguage = () => {
    const key = localStorage.getItem("crp_lang");
    const selected = languageOptions.find((item) => item.key === key);
    if (selected)
      return selected;
    return languageOptions[0]
  }
  const [selectedLanguage, setSelectedLanguage] = useState(languageOptions[0]);

  useEffect(() => {
    let initial = getInitialLanguage();
    dispatch(switchLanguage((AppLocale as any)[initial.key]));
    setSelectedLanguage(initial);
  }, []);

  const handleLanguageSelect = (language: any) => {
    console.log(language)
    const lang = language.key;
    localStorage.setItem("crp_lang", lang);
    localStorage.setItem("crp_isRTL", lang === "ar" ? "true" : "false");
    if (lang === "ar") dispatch(setDirectionRTL(true));
    else dispatch(setDirectionRTL(false));

    dispatch(switchLanguage((AppLocale as any)[lang]));
    setSelectedLanguage(language);
  };

  const languageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover">
        {languageOptions.map(language =>
          <li className="gx-media gx-pointer" key={(language?.key)} onClick={() => handleLanguageSelect(language)}  >
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
            <span className="gx-language-text gx-mt-1">{language.name}</span>
          </li>
        )}
      </ul>
    </CustomScrollbars>);

  return (
    <div>
      <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={languageMenu()}
        trigger="click">
        <span className="gx-pointer gx-flex-row gx-align-items-center gx-mr-4">
          <i className={`flag flag-24 flag-${selectedLanguage?.icon}`} />
          <span className="gx-pl-2 gx-language-name">{selectedLanguage?.name}</span>
          <i className="icon icon-chevron-down gx-pl-2 gx-mt-2" />
        </span>
      </Popover>
    </div>

  );
};

export default LanguageMenu;
