export const PERMISSIONS = {
  LINES_XSEN030_P10: "lines.xsen030.p10",
  LINES_XSEN030_P30: "lines.xsen030.p30",
  LINES_XSEN030_DAILY: "lines.xsen030.daily",
  LINES_XSEN070_P10: "lines.xsen070.p10",
  LINES_XSEN070_P30: "lines.xsen070.p30",
  LINES_XSEN070_DAILY: "lines.xsen070.daily",
  LINES_XSEN500_P10: "lines.xsen500.p10",
  LINES_XSEN500_P30: "lines.xsen500.p30",
  LINES_XSEN500_DAILY: "lines.xsen500.daily",
  SIGNALS_XSEN030_P10: "signals.xsen030.p10",
  SIGNALS_XSEN030_P30: "signals.xsen030.p30",
  SIGNALS_XSEN030_DAILY: "signals.xsen030.daily",
  SIGNALS_XSEN070_P10: "signals.xsen070.p10",
  SIGNALS_XSEN070_P30: "signals.xsen070.p30",
  SIGNALS_XSEN070_DAILY: "signals.xsen070.daily",
  SIGNALS_XSEN500_P10: "signals.xsen500.p10",
  SIGNALS_XSEN500_P30: "signals.xsen500.p30",
  SIGNALS_XSEN500_DAILY: "signals.xsen500.daily",
  BIST_HOME_XSEN030: "bist-home.xsen030",
  BIST_HOME_XSEN070: "bist-home.xsen070",
  BIST_HOME_XSEN500: "bist-home.xsen500",
  TRADES_XSEN030: "trades.xsen030",
  TRADES_XSEN070: "trades.xsen070",
  TRADES_XSEN500: "trades.xsen500",
  SHORTTRADES_XSEN030: "shortTrades.xsen030",
  SHORTTRADES_XSEN070: "shortTrades.xsen070",
  WEB_ZEPHLEX_DAILY: "web-zephlex.daily",
  WEB_ZEPHLEX_MINUTE: "web-zephlex.minute",
  WEB_ZEPHLEX_SENTIMETER: "web-zephlex.sentimeter",
  WEB_ZEPHLEX_HOUR: "web-zephlex.hourly",
  WEB_ZEPHLEX_DASHBOARD: "web-zephlex.dashboard",
  WEB_ZEPHLEX_PREMIUM_MESSAGE: "web-zephlex.premium-message",
  CRYPTO: "crypto.all",
};

export const isCooperateUser = (nsUser: any) => {
  if (!nsUser || !nsUser?.user) return false;
  if (nsUser?.cooperate) return true;
  return false;
};

export const hasPermission = (nsUser: any, permission: string) => {
  if (!nsUser || !nsUser?.user) return false;
  if (nsUser?.user?.[PERMISSIONS.CRYPTO]) return true;
  if (isCooperateUser(nsUser)) return true;
  return nsUser?.user?.[permission] === true;
};

export const hasOneOfPermissions = (nsUser: any, permissions: string[]) => {
  if (!nsUser || !nsUser?.user) return false;
  if (nsUser?.user?.[PERMISSIONS.CRYPTO]) return true;

  if (isCooperateUser(nsUser)) return true;
  return permissions.some((permission) => nsUser?.user?.[permission] === true);
};
