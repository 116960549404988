import { Button, Modal, Select } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  loadSentimentPower,
} from "../../../appRedux/reducers/sentiment/sentiment-power";
import { nsUserSelector } from "../../../appRedux/reducers/sentiment/user";
import {
  loadXu30Daily,
} from "../../../appRedux/reducers/sentiment/xu030";
import {
  loadXu100Daily,
} from "../../../appRedux/reducers/sentiment/xu100";
import {
  loadDailyInstantSentiment,
} from "../../../appRedux/reducers/web/daily-instant-sentiment";
import { firebaseApp } from "../../../firebase";
import "react-circular-progressbar/dist/styles.css";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";
import "./index.css";
import { loadCrypto15 } from "../../../appRedux/reducers/sentiment/crypto-15";
import { crypto1Selector } from "../../../appRedux/reducers/sentiment/crypto-15";
import LanguageMenu from "../../../components/LanguageMenu/LanguageMenu";
import { SettingOutlined } from "@ant-design/icons";
import { THEME_TYPE_DARK, THEME_TYPE_LITE, THEME_TYPE_SEMI_DARK } from "../../../constants/ThemeSetting";
import { setThemeType } from "../../../appRedux/actions/Setting";

const POWER_SCALE = ["#ff0000", "#ff9933", "#FDDD60", "#39d121"];
const Option = Select.Option;
export const Header = (props: any) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const nsUser = useSelector(nsUserSelector);

  useEffect(() => {
    dispatch(loadXu100Daily(firebaseApp));
    dispatch(loadXu30Daily(firebaseApp));
    dispatch(loadDailyInstantSentiment(firebaseApp));
    dispatch(loadSentimentPower(firebaseApp));
  }, [nsUser]);

  useEffect(() => {
    if (
      "Notification" in window &&
      Notification.permission !== "granted" &&
      Notification.permission !== "denied" &&
      localStorage.getItem("notification_permission_requested") !== "true"
    ) {
      localStorage.setItem("notification_permission_requested", "true");
      toast.info("Masaüstü bildirimlerine izin vermek için tıklayınız.", {
        delay: 2000,
        autoClose: 5000,
        position: "top-center",
        hideProgressBar: true,
        closeOnClick: true,
        onClick: () => {
          if ("Notification" in window) Notification.requestPermission();
        },
      });
    }
  }, []);

  const handleKlineIntervalChange = (interval: any) => {
    localStorage.setItem("crpklineInterval", interval);
    window.location.reload();
  };

  const getKlineColor = () => {
    var klineInterval = localStorage.getItem("crpklineInterval");
    if (klineInterval == "16H") return "gx-text2-info gx-border-white gx-bg-weekly";
    if (klineInterval == "1H") return "gx-text2-info gx-border-white gx-bg-hourly";
    if (klineInterval == "G") return " gx-text2-orange gx-border-orange gx-bg-daily";
    return "";
  };

  const onThemeTypeChange = (e: any) => {
    dispatch(setThemeType(e));
  };

  useEffect(() => {
    dispatch(loadCrypto15(firebaseApp));
  }, []);
  const data = useSelector(crypto1Selector);

  const sentiment = Number(data?.sentiment?.length > 0 && data.sentiment[data.sentiment.length - 1].value[1])
  const roundedString = sentiment.toFixed(3);
  const roundedSentiment = Number(roundedString);
  const btcPrice = data?.btcusdt?.length > 0 && (data.btcusdt[data.btcusdt.length - 1].value[1])
  const ethPrice = data?.ethusdt?.length > 0 && (data.ethusdt[data.ethusdt.length - 1].value[1])

  return (
    <div className={"custom-header gx-pb-2 gx-pt-2 " + getKlineColor()} style={{ borderBottom: "2px solid #e8e8e8" }}>
      <div
        style={{ display: "flex", justifyContent: "space-between", flex: 1 }}
      >
        <div className="custom-header-content">
          <div className="header-desktop-value">
            <div>BTC: {btcPrice} </div>
            <div className="gx-ml-4">ETH: {ethPrice} </div>
            <div className="gx-ml-4">Sentiment: {roundedSentiment} </div>
          </div>
        </div>
      </div>
      <div className="header-desktop-settings">
        <div>
          <Select
            defaultValue={localStorage.getItem("crpklineInterval") ?? ""}
            className="gx-mr-4"
            style={{ width: 180, padding: "2px" }}
            onChange={handleKlineIntervalChange}
          >
            <Option value="1H">1H Veriler</Option>
            <Option value="">4H Veriler</Option>
            <Option value="16H">16H Veriler</Option>
            <Option value="G">Günlük Veriler</Option>
          </Select>
        </div>

        <LanguageMenu setShowsSettings={props.setShowsSettings} />

        {/* <Select
          className="gx-mr-4"
          style={{ width: 180, padding: "2px" }}
          onChange={onThemeTypeChange}
        >
          <Option value={THEME_TYPE_LITE}>Lite</Option>
          <Option value={THEME_TYPE_SEMI_DARK}>Semi Dark</Option>
          <Option value={THEME_TYPE_DARK}>Dark</Option>
        </Select> */}
        <div
          className="header-settings-icon gx-mr-4"
        >
          <SettingOutlined
            onClick={() => {
              props.setShowsSettings(true);
            }}
            style={{ fontSize: 17 }}
          />
        </div>
      </div>
    </div>
  );
};
