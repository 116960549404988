import moment from "moment";
import { SENTIMENT_ACTION } from "./actions";
import { IData } from "../web/common";
import klineInterval from "../Params";

export const DATA_COUNT_70 = 100;
const COLLECTION_NAME = "NS_CRP_LINES_70";

interface IXUData {
  lines70: IData[];
  requested: boolean;
  loaded: boolean;
}

const initialState: IXUData = {
  lines70: [],
  requested: false,
  loaded: false,
};

export function loadLines70(firebase: any, period: number, historyDate: Date  | null= null) {
  if (period < 0 || ![0, 10, 30, 99].includes(period)) {
    console.log("Invalid period for line 70. Period:", period);
    return async function thunk(dispatch: any, getState: any) {
      dispatch({
        type: SENTIMENT_ACTION.EMPTY_ACTION,
        payload: {},
      });
    };
  }
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    historyDate = historyDate ?? new Date();
    if (state.Lines70.requested && state.selectedDate == historyDate) return;
    dispatch({
      type: SENTIMENT_ACTION.LINES_70,
      payload: { requested: true, loaded: false, sentiment: [] },
    });

    firebase
      .firestore()
      .collection(`${COLLECTION_NAME}${klineInterval}_P${period}`)
      //.where("dateTime", "<=", historyDate)
      .orderBy("dateTime", "desc")      
      .limit(DATA_COUNT_70)
      .onSnapshot((querySnapshot: any) => {
        var lines70Data: Object[] = [];

        querySnapshot.forEach(function (doc: any) {
          //if (lines70Data.some((x: any) => { x.Symbol == doc.data().symbol })) return;
          var sentiPoints = doc.data().sentimentList
          var SentiPer = 0
          if(sentiPoints?.length>0)
          SentiPer = ((sentiPoints[sentiPoints.length - 1] - sentiPoints[sentiPoints.length - 2]) / sentiPoints[sentiPoints.length - 2] * 100)
          lines70Data.push({
            name: "lines70",
            Index: doc.data().index,
            Symbol: doc.data().symbol,
            Sentiment: doc.data().sentiment,
            Order: doc.data().order,
            Grup: doc.data().grup,
            ODate: doc.data().openDate,
            OPrice: doc.data().openPrice,
            Price: doc.data().price,
            DailyChange: doc.data().dailyChange,
            Vbts: doc.data().vbts,
            Viop: doc.data().viop,
            PerDay: doc.data().perDay,
            Vol: doc.data().vol,
            VolPerDay: doc.data().volPerDay,
            SentimentList: doc.data().sentimentList,
            SentiPer: SentiPer,
            PriceList: doc.data().priceList,
            ChangeList: doc.data().changeList,
            ChangeList3: doc.data().changeList3,
            ChangeList4: doc.data().changeList4,
            ChangeList5: doc.data().changeList5,
            ChangeList6: doc.data().changeList6,
            ChangeList7: doc.data().changeList7,
            ReChangeList: doc.data().changeList.slice().reverse(),
            dateTime: doc.data().dateTime.toDate(),
            Time: doc.data().time ?? 0,
            UpdateTime: moment
              .unix(doc.data().dateTime.seconds)
              .format("DD.MM.yy HH:mm"),
            LineDate: doc.data().dateTime.seconds,
            STP: doc.data().vwma?.reverse(),
          });
        });

        dispatch({
          type: SENTIMENT_ACTION.LINES_70,
          payload: {
            lines70: lines70Data.sort((a: any, b: any) => { return a.Order - b.Order }),
            loaded: true,
            requested: true,
            selectedDate: historyDate
          },
        });
      });
  };
}

export default function Lines70(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.LINES_70:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const lines70Selector = (state: any) => state.Lines70;
