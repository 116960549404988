import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import Common from "./Common";
import DailySuggestions from "./sentiment/daily-suggestions";
import Lines30 from "./sentiment/lines-30";
import Lines500 from "./sentiment/lines-500";
import Lines70 from "./sentiment/lines-70";
import News from "./sentiment/news";
import Portfolio from "./sentiment/portfolio";
import Signal30 from "./sentiment/signal-30";
import Signal500 from "./sentiment/signal-500";
import Signal70 from "./sentiment/signal-70";
import Trade30 from "./sentiment/trade-30";
import Trade500 from "./sentiment/trade-500";
import Trade70 from "./sentiment/trade-70";
import NsUser from "./sentiment/user";
import Xsen030DailyBalance from "./sentiment/xsen030-daily-balance";
import Xsen030MonthlyBalance from "./sentiment/xsen030-monthly-balance";
import Xsen070DailyBalance from "./sentiment/xsen070-daily-balance";
import Xsen070MonthlyBalance from "./sentiment/xsen070-monthly-balance";
import Xsen500DailyBalance from "./sentiment/xsen500-daily-balance";
import Xsen500MonthlyBalance from "./sentiment/xsen500-monthly-balance";
import XU30Daily from "./sentiment/xu030";
import XU100Daily from "./sentiment/xu100";
import BtcDailyBalance from "./sentiment/btc-daily-balance";
import Settings from "./Settings";
import DailyInstantSentiment from "./web/daily-instant-sentiment";
import DailySentiment from "./web/daily-sentiment";
import HourlySentiment from "./web/hourly-sentiment";
import Osilator from "./web/osilator";
import Senti1 from "./web/senti-1";
import Senti15 from "./web/senti-15";
import LinesDates from "./sentiment/lines-dates";
import BistTop30 from "./sentiment/bist-top-30";
import BistTop70 from "./sentiment/bist-top-70";
import BistPerformanceDaily from "./sentiment/bist-performance-daily";
import BistPerformanceWeekly from "./sentiment/bist-performance-weekly";
import BistPerformanceMonthly from "./sentiment/bist-performance-monthly";
import Worst30 from "./sentiment/worst-30";
import Worst70 from "./sentiment/worst-70";
import Worst500 from "./sentiment/worst-500";
import Messages from "./web/messages";
import WebUser from "./web/user";
import Lines500Snapshot from "./sentiment/lines-500-snapshot"
import Lines70Snapshot from "./sentiment/lines-70-snapshot"
import Lines30Snapshot from "./sentiment/lines-30-snapshot"
import Notifications from "./sentiment/notifications";
import Xu100MonthlyBalance from "./sentiment/xu100-monthly-balance";
import Senti1Snapshot from "./web/senti-1-snapshot";
import ShortTrade30 from "./sentiment/shortTrade-30";
import ShortTrade70 from "./sentiment/shortTrade-70";
import SentimentPower from "./sentiment/sentiment-power";
import Crypto15 from "./sentiment/crypto-15";
import Crypto60 from "./sentiment/crypto-60";
import CryptoAlarmState from "./sentiment/crp-alarm-state";
import CryptoAlarm from "./sentiment/crp-alarm";
import Lines from "./sentiment/lines"
import Timeline30 from "./sentiment/timeline-30";
import Timeline70 from "./sentiment/timeline-70";
import Timeline500 from "./sentiment/timeline-500";

const createRootReducer = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    common: Common,
    DailySentiment,
    DailyInstantSentiment,
    HourlySentiment,
    Osilator,
    Senti1,
    Senti15,
    Lines,
    XU30Daily,
    XU100Daily,
    Xu100MonthlyBalance,
    Xsen030MonthlyBalance,
    Xsen070MonthlyBalance,
    Xsen500MonthlyBalance,
    Portfolio,
    News,
    DailySuggestions,
    Timeline30,
    Timeline70,
    Timeline500,
    Lines30,
    Lines70,
    Lines500,
    Signal30,
    Signal70,
    Signal500,
    Xsen030DailyBalance,
    Xsen070DailyBalance,
    Xsen500DailyBalance,
    BtcDailyBalance,
    Trade30,
    Trade70,
    Trade500,
    ShortTrade30,
    ShortTrade70,
    NsUser,
    LinesDates,
    BistTop30,
    BistTop70,
    BistPerformanceDaily,
    BistPerformanceWeekly,
    BistPerformanceMonthly,
    Worst30,
    Worst70,
    Worst500,
    Messages,
    WebUser,
    Lines30Snapshot,
    Lines70Snapshot,
    Lines500Snapshot,
    Notifications,
    Senti1Snapshot,
    SentimentPower,
    Crypto15,
    Crypto60,
    CryptoAlarmState,
    CryptoAlarm
  });

export default createRootReducer;
