import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";

import {
  loadNotifications,
  notificationSelector,
} from "../../appRedux/reducers/sentiment/notifications";
import { useAuth } from "../../authentication";
import { firebaseApp } from "../../firebase";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import MenuItem from "antd/lib/menu/MenuItem";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;



const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const dispatch = useDispatch();

  const { navStyle, themeType } = useSelector(({ settings }) => settings);
  const pathname = useSelector(({ common }) => common?.pathname);
  const { authUser } = useAuth();

  useEffect(() => {
    dispatch(loadNotifications(firebaseApp, authUser?.uid));
  }, [authUser]);
  const intl = useIntl();
  const notifications = useSelector(notificationSelector);
  const unreadNotifications = notifications && notifications?.data ? notifications?.data?.filter(n => !n.read).length : 0;
  const getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  const { userSignOut } = useAuth();
  const history = useHistory();
  const onLogoutClick = () => {
    userSignOut(() => {
      history.push('/');
    });
  }

  const selectedKeys = pathname?.substr(1);
  const defaultOpenKeys = selectedKeys?.split('/')[1];

  return (
    <>
      <SidebarLogo sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed} />
      <div className="gx-sidebar-content">
        <div className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}>
          <UserProfile />
          <AppsNavigation />
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={"crypto"}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline">

            <Menu.Item key="index">
              <Link to="/index/home">

                <span> <i className="icon icon-chart-line" />{intl.formatMessage({ id: "sidebar.index" })}</span>
              </Link>
            </Menu.Item>

            <Menu.SubMenu key="crypto" popupClassName={getNavStyleSubMenuClass(navStyle)}
              title={<span> <i className="icon icon-chart-area" />{intl.formatMessage({ id: "sidebar.crypto" })}</span>}>
              <Menu.Item key="crypto/home">
                <Link to="/crypto/home"><i className="icon icon-menu" /><span>{intl.formatMessage({ id: "sidebar.home" })}</span></Link>
              </Menu.Item>
              <Menu.Item key="crypto/lines">
                <Link to="/crypto/lines"><i className="icon icon-widgets" /><span>{intl.formatMessage({ id: "sidebar.symbols" })}</span></Link>
              </Menu.Item>
              <Menu.Item key="crypto/explore">
                <Link to="/crypto/explore"><i className="icon icon-search-new" /><span>{intl.formatMessage({ id: "sidebar.explore" })}</span></Link>
              </Menu.Item>
              <Menu.Item key="crypto/follower">
                <Link to="/crypto/follower"><i className="icon icon-filter" /><span>{intl.formatMessage({ id: "sidebar.follower" })}</span></Link>
              </Menu.Item>
              <Menu.Item key="crypto/signals">
                <Link to="/crypto/signals"><i className="icon icon-megaphone" /><span>{intl.formatMessage({ id: "sidebar.top_ten_lists" })}</span></Link>
              </Menu.Item>
              <Menu.Item key="crypto/timeline">
                <Link to="/crypto/timeline"><i className="icon icon-map-clustering" /><span>{intl.formatMessage({ id: "sidebar.symbol_signal" })}</span></Link>
              </Menu.Item>
              <Menu.Item key="crypto/trades">
                <Link to="/crypto/trades"><i className="icon icon-pricing-table" /><span>{intl.formatMessage({ id: "sidebar.top_ten_history" })}</span></Link>
              </Menu.Item>
              <Menu.Item key="crypto/livePrices">
                <Link to="/crypto/livePrices"><i className="icon icon-bitcoin" /><span>{intl.formatMessage({ id: "sidebar.live_prices" })}</span></Link>
              </Menu.Item>
              <Menu.Item key="crypto/liveTrade">
                <Link to="/crypto/liveTrade"><i className="icon icon-chart" /><span>{intl.formatMessage({ id: "sidebar.live_trade" })}</span></Link>
              </Menu.Item>
              {/* <Menu.Item key="crypto/widgets">
                <Link to="/crypto/widgets"><i className="icon icon-chart" /><span>{intl.formatMessage({ id: "sidebar.live_trade" })}</span></Link>
              </Menu.Item> */}
            </Menu.SubMenu>
            <Menu.Item key="account">
              <Link to="/account">
                <i className="icon icon-profile2" />
                <span>{intl.formatMessage({ id: "sidebar.account" })}</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="exit">
              <Link onClick={onLogoutClick}>
                <i className="icon icon-close-circle" />
                <span>{intl.formatMessage({ id: "sidebar.exit" })} </span>
              </Link>
            </Menu.Item>

          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

export default React.memo(SidebarContent);

