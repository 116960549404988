import moment from "moment";
import { SENTIMENT_ACTION } from "./actions";
import { IData } from "../web/common";

const DATA_COUNT = 90;
const COLLECTION_NAME = "NS_XU100_DAILY";

interface IXUData {
  xu100Daily: IData[];
  requested: boolean;
  loaded: boolean;
}

const initialState: IXUData = {
  xu100Daily: [],
  requested: false,
  loaded: false,
};

export function loadXu100Daily(firebase: any) {
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.XU100Daily.requested) return;
    dispatch({
      type: SENTIMENT_ACTION.XU100_DAILY,
      payload: { requested: true, loaded: false, sentiment: [] },
    });

    firebase
      .firestore()
      .collection(COLLECTION_NAME)
      .orderBy("dateTime", "desc")
      .limit(DATA_COUNT)
      .onSnapshot((querySnapshot: any) => {
        var xu100Data: Object[] = [];

        querySnapshot.forEach(function (doc: any) {
          const dateVal = moment
            .unix(doc.data().dateTime.seconds)
            .format("YYYY/MM/DD");
          xu100Data.push({
            name: "close",
            close: doc.data().close,
            dateTime: doc.data().dateTime.toDate(),
          });
        });

        xu100Data = xu100Data.reverse();

        dispatch({
          type: SENTIMENT_ACTION.XU100_DAILY,
          payload: {
            xu100Daily: xu100Data,
            loaded: true,
            requested: true,
          },
        });
      });
  };
}

export default function XU100Daily(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.XU100_DAILY:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const xu100DailySelector = (state: any) => state.XU100Daily;
