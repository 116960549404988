import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import styled from "styled-components";

const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;
const Widget = styled.div<{ height: number }>`
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;

type Props = {
  height?: number;
};

export const LoadingWidget = ({ height }: Props) => {
  return (
    <Widget height={height??100}>
      <Spin indicator={antIcon} /> 
    </Widget>
  );
};
