import moment from "moment";
import { EMA } from "technicalindicators";
import { IData } from "../web/common";
import { SENTIMENT_ACTION } from "./actions";

const DATA_COUNT = 330;
const COLLECTION_NAME = "CRYPTO60";

interface ISenti1Data {
  sentiment: IData[];
  bist100: IData[];
  bist30: IData[];
  viop30: IData[];
  ema30Sentiment: IData[];
  ema10Sentiment: IData[];
  requested: boolean;
  loaded: boolean;
}

const initialState: ISenti1Data = {
  sentiment: [],
  bist100: [],
  bist30: [],
  viop30: [],
  ema30Sentiment: [],
  ema10Sentiment: [],
  requested: false,
  loaded: false,
};

function getEmaSentiment(sentimentData: any, period: number) {
  var ema30Sentiment: any[] = [...sentimentData];
  var ema30Values = ema30Sentiment.map((ema: any) => ema.value[1]);
  let ema30Result = EMA.calculate({ period: period, values: ema30Values });
  let ema30UpdatedSentiment: any = [];
  ema30Result.forEach((value, idx) => {
    ema30UpdatedSentiment.push({
      name: ema30Sentiment[idx].name,
      value: [ema30Sentiment[idx].value[0], value],
      date: ema30Sentiment[idx].date,
    });
  });
  ema30Sentiment = ema30UpdatedSentiment;
  return ema30UpdatedSentiment.reverse();
}

const clearEmaValues = (sentimentData: any, emaData: any) => {
  for (var i = 0; i < emaData.length; i++) {
    const sentiData: any = sentimentData[0];
    if (emaData[0].value[0] != sentiData.value[0]) emaData.splice(0, 1);
    else break;
  }
};

export function loadCrypto60(firebase: any) {
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.Senti1.requested) return;
    dispatch({
      type: SENTIMENT_ACTION.CRYPTO_60,
      payload: { requested: true, loaded: false, sentiment: [] },
    });

    firebase
      .firestore()
      .collection(COLLECTION_NAME)
      .orderBy("dateTime", "desc")
      .limit(DATA_COUNT)
      .onSnapshot((querySnapshot: any) => {
        var sentimentData: Object[] = [];
        var btcData: Object[] = [];
        var ethData: Object[] = [];
        querySnapshot.forEach(function (doc: any) {
          const dateVal = moment
            .unix(doc.data().dateTime.seconds)
            .format("YYYY/MM/DD HH:mm");
          sentimentData.push({
            name: "sentiment",
            value: [dateVal, parseFloat(doc.data().value.toFixed(2))],
            date: doc.data().dateTime.seconds,
          });
          btcData.push({
            name: "btc",
            value: [dateVal, parseFloat(doc.data().btcusdt.toFixed(2))],
            date: doc.data().dateTime.seconds,
          });
          ethData.push({
            name: "eth",
            value: [dateVal, parseFloat(doc.data().ethusdt.toFixed(2))],
            date: doc.data().dateTime.seconds,
          });
        });
        const ema30 = getEmaSentiment(sentimentData, 30);
        const ema10 = getEmaSentiment(sentimentData, 8);
        sentimentData = sentimentData.reverse();
        btcData = btcData.reverse();
        ethData = ethData.reverse();
        sentimentData.splice(0, 29);
        btcData.splice(0, 29);
        ethData.splice(0, 29);
        clearEmaValues(sentimentData, ema30);
        clearEmaValues(sentimentData, ema10);
        dispatch({
          type: SENTIMENT_ACTION.CRYPTO_60,
          payload: {
            sentiment: sentimentData,
            btcusdt: btcData,
            ethusdt: ethData,
            ema30Sentiment: ema30,
            ema10Sentiment: ema10,
            loaded: true,
            requested: true,
          },
        });
      });
  };
}

export default function Crypto60(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.CRYPTO_60:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const crypto60Selector = (state: any) => state.Crypto60;
