export enum SENTIMENT_ACTION {
  XU030_DAILY = "XU030_DAILY",
  XU100_DAILY = "XU100_DAILY",
  XU100_MONTHLY_BALANCE = "XU100_MONTHLY_BALANCE",
  XSEN030_MONTHLY_BALANCE = "XSEN030_MONTHLY_BALANCE",
  XSEN070_MONTHLY_BALANCE = "XSEN070_MONTHLY_BALANCE",
  XSEN500_MONTHLY_BALANCE = "XSEN500_MONTHLY_BALANCE",
  XSEN030_DAILY_BALANCE = "XSEN030_DAILY_BALANCE",
  XSEN070_DAILY_BALANCE = "XSEN070_DAILY__BALANCE",
  XSEN500_DAILY_BALANCE = "XSEN500_DAILY_BALANCE",
  BTC_DAILY_BALANCE = "BTC_DAILY_BALANCE",
  PORTFOLIO = "PORTFOLIO",
  NEWS = "NEWS",
  NOTIFICATIONS = "notifications",
  DAILY_SUGGESTIONS = "DAILY_SUGGESTIONS",
  LINES = "LINES",
  TIMELINE_30 = "TIMELINE_30",
  TIMELINE_70 = "TIMELINE_70",
  TIMELINE_500 = "TIMELINE_500",
  LINES_30 = "LINES_30",
  LINES_70 = "LINES_70",
  LINES_500 = "LINES_500",
  LINES_30_SNAPSHOT = "LINES_30_SNAPSHOT",
  LINES_70_SNAPSHOT = "LINES_70_SNAPSHOT",
  LINES_500_SNAPSHOT = "LINES_500_SNAPSHOT",
  SIGNAL_30 = "SIGNAL_30",
  SIGNAL_70 = "SIGNAL_70",
  SIGNAL_500 = "SIGNAL_500",
  WORST_30 = "WORST_30",
  WORST_70 = "WORST_70",
  WORST_500 = "WORST_500",
  TRADE_30 = "TRADE_30",
  TRADE_70 = "TRADE_70",
  SHORTTRADE_30 = "SHORTTRADE_30",
  SHORTTRADE_70 = "SHORTTRADE_70",
  TRADE_500 = "TRADE_500",
  NS_USER = "NS_USER",
  NS_LINES_DATE_LIST = "NS_LINES_DATE_LIST",
  BIST_TOP_30 = "NS_BIST_TOP_30",
  BIST_TOP_70 = "NS_BIST_TOP_70",
  BIST_PERFORMANCE_DAILY = "BIST_PERFORMANCE_DAILY",
  BIST_PERFORMANCE_WEEKLY = "BIST_PERFORMANCE_WEEKLY",
  BIST_PERFORMANCE_MONTHLY = "BIST_PERFORMANCE_MONTHLY",
  EMPTY_ACTION = "EMPTY_ACTION",
  SENTIMENT_POWER = "SENTIMENT_POWER",
  CRYPTO_15 = "CRYPTO_15",
  CRYPTO_60 = "CRYPTO_60",
  CRYPTO_ALARM_STATE = "CRYPTO_ALARM_STATE",
  CRYPTO_ALARM = "CRYPTO_ALARM",
}
