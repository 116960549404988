import moment from "moment";
import { SENTIMENT_ACTION } from "./actions";
import { IData } from "../web/common";
import klineInterval from "../Params";

const DATA_COUNT = 12*150;
const COLLECTION_NAME = "NS_CRP_TRADES_500";

interface IXUData {
  trade500: IData[];
  requested: boolean;
  loaded: boolean;
}

const initialState: IXUData = {
  trade500: [],
  requested: false,
  loaded: false,
};

export function loadTrade500(firebase: any) {
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.Trade500.requested) return;
    dispatch({
      type: SENTIMENT_ACTION.TRADE_500,
      payload: { requested: true, loaded: false, sentiment: [] },
    });
    firebase
      .firestore()
      .collection(`${COLLECTION_NAME}${klineInterval}`)
      .orderBy("dateTime", "desc")
      .limit(DATA_COUNT)
      .onSnapshot((querySnapshot: any) => {
        var trade500Data: Object[] = [];

        querySnapshot.forEach(function (doc: any) {
          var opendt = doc.data().openDate.split(".");

          trade500Data.push({
            uid: doc.data().symbol + '_' + doc.data().openDate,
            name: "trade500",
            Index: doc.data().index,
            DataPackage: doc.data().dataPackage,
            Date: moment(doc.data().dateTime.toDate()).format("DD.MM.YY HH:mm"),
            Symbol: doc.data().symbol,
            ODate: moment(doc.data().openDate, "DD.MM.YYYY HH:mm").format("DD.MM.YY HH:mm"),
            CDate: doc.data().closeDate != "" ? moment(doc.data().closeDate, "DD.MM.YYYY HH:mm").format("DD.MM.YY HH:mm") : "",
            OPrice: doc.data().openPrice,
            CPrice: doc.data().closePrice,
            PerDay: doc.data().perDay,
            Percent: doc.data().percent,
            Duration: doc.data().closeDate != "" ? moment(doc.data().closeDate, "DD.MM.YYYY").diff(moment(doc.data().openDate, "DD.MM.YYYY"), 'days') : 0,
            dateTime: doc.data().dateTime.toDate(),
            dateOpen: new Date(opendt[2],opendt[1]-1, opendt[0])
          });
        });

        dispatch({
          type: SENTIMENT_ACTION.TRADE_500,
          payload: {
            trade500: trade500Data,
            loaded: true,
            requested: true,
          },
        });
      });
  };
}

export default function Trade500(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.TRADE_500:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const trade500Selector = (state: any) => state.Trade500;
