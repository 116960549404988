import { SENTIMENT_ACTION } from "./actions";

const DATA_COUNT = 10;
const COLLECTION_NAME = "NS_CRP_ALARM";
interface CRP_ALARM {
  name: string;
  id: string;
}

interface ICRPAlarmState {
  alarms: CRP_ALARM[];
  requested: boolean;
  loaded: boolean;
}

const initialState: ICRPAlarmState = {
  alarms: [],
  requested: false,
  loaded: false,
};

export function loadCryptoAlarm(firebase: any, ownerId: string) {
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.CryptoAlarm.requested) return;
    dispatch({
      type: SENTIMENT_ACTION.CRYPTO_ALARM,
      payload: { requested: true, loaded: false, sentiment: [] },
    });
    firebase
      .firestore()
      .collection(COLLECTION_NAME)
      .doc(ownerId)
      .onSnapshot((querySnapshot: any) => {
        if (!querySnapshot.exists) return;

        const alarms: any = [];
        querySnapshot.data()?.alarms.forEach(function (alarm: any) {
          alarms.push(alarm);
        });

        dispatch({
          type: SENTIMENT_ACTION.CRYPTO_ALARM,
          payload: {
            alarms,
            loaded: true,
            requested: true,
          },
        });
      });
  };
}

export default function CryptoAlarm(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.CRYPTO_ALARM:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const cryptoAlarmSelector = (state: any) => state.CryptoAlarm;
